import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'
import Plaatjie from '@ubo/plaatjie'

const Section = styled.section`
  z-index: 3;

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.ultra};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }
  }
`

const Icon = styled(Plaatjie)`
  @media (max-width: 1199.98px) {
    width: 70%;
    height: 70%;
    margin-bottom: 1.5rem;
    max-width: 200px;
  }
`

const TextIcon: React.FC<TextProps> = ({ fields }) => {
  return (
    <Section className="position-relative">
      <div className="container py-5">
        <div className="row align-items-end justify-content-center">
          <div className={`col-lg-${fields.containerwidth}`}>
            <div className="row">
              <div className="col-md-6 col-lg-5">
                <div className="pe-lg-5">
                  <Icon image={fields.icon} alt="" />
                </div>
              </div>
              <div className="col-md-6 col-lg-7">
                <ParseContent
                  className="ps-lg-5"
                  content={fields.description}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TextIcon
